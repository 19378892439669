.listItemWrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
  }
  
  @media (min-width: 769px) {
    .listWrapper {
        width: 100%;
    }
  }
  
  .listWrapper::-webkit-scrollbar {
    display: none;
  }

.initial {
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 47px;
    color: #505050;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .productDescription {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 14px;
    width: 80%;
  }
  
  .detailsWrapper {
    width: 90%;
    font-size: 18px;
    text-align: left;
    color: #505050;
  }




  .newListItemWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-right: 24px;
    position: relative;
    align-items: flex-start;
    font-size: 18px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
  }

  .itemHeader {
    font-size: 14px;
    color: gray;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .itemDetails {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }

  .breakdownDetails {
    margin-top: 8px;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .paymentType {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .paymentTypeInner {
    display: flex;
    align-items: center;
    align-items: center;
  }

  .breakdownType {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
    border-top: 1px lightgray solid;
  }

  .staffBreakdownType {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
  }

  .staffBreakdownTypeHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
    color: gray;
  }

  .staffTotalsAndTally {
    width: 35%;
    max-width: 150px;
    display: flex;
    justify-content: space-between;
  }

  .breakdownType:nth-child(1) {
    border-top: none;
  }

  .breakdownType2 {
    border-top: none;
    padding: 2px 0px;
  }

  .breakdownType2:nth-child(4){
    margin-bottom: 8px;
  }

  .saleBreakdown {
    margin-left: 16px;
  }

  .paymentTypeIcon {
    display: flex;
  }

  .klarnaIcon {
    background-image: url("../icons/klarna.webp");
    width: 20px;
    height: 13px;
    background-position: center;
    background-size: cover;
    margin-right: 16px;
  }