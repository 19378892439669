.listItemWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-right: 24px;
  box-shadow: 0 2px 10px rgba(15, 15, 28, 0.203);
  margin: 12px;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.productDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 14px;
  width: 80%;
}

.productDescriptionActive {
  text-align: left;
  font-size: 14px;
  width: 80%;
}

.productDescriptionText {
  color: grey;
}

.productPrice {
  text-align: right;
  margin-top: 8px;
  font-size: 16px;
}

.detailsWrapper {
  width: 90%;
  font-size: 18px;
  text-align: left;
  color: #505050;
}

.addSale {
  position: absolute;
  right: 16px;
  top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.salesLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  text-decoration: underline;
  text-align: right;
  margin-top: 8px;
  color: grey;
}

.actions > span {
  margin-left: 16px;
}

.listWrapper {
  margin-top: 68px;
}

@media (min-width: 769px) {
  .listWrapper {
    width: calc(100vw - 270px);
  }
}

@media (min-width: 769px) {
  .listDesktopWrapper {
    height: calc(100vh);
    overflow: scroll;
  }
}

.listWrapper::-webkit-scrollbar {
  display: none;
}

.calenderIcon {
  position: absolute;
  z-index: 100;
  height: 28px;
  width: 28px;
  right: 60px;
  top: 16px;
  color: white;
}

.backNavigation {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 100;
}

.initial {
  font-size: 18px;
  width: 20px;
  height: 20px;
  line-height: 47px;
  color: #505050;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topRight {
  position: absolute;
  right: 16px;
  top: 0px;
  line-height: 50px;
  height: 50px;
  color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.topRightUser {
  margin-left: 8px;
}

.divider {
  width: calc(100% - 32px);
  height: 1px;
  margin: 32px 16px;
  border-bottom: 1px lightgray solid;
}