.wrapper {
  width: calc(100% - 40px);
  margin: 20px;
  margin-top: 70px;
}

.listItemWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-right: 24px;
  box-shadow: 0 2px 10px rgba(15, 15, 28, 0.203);
  margin: 12px;
  position: relative;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.listWrapper {
  width: calc(100% - 40px);
  margin: 20px;
  margin-top: 68px;
}

@media (min-width: 769px) {
  .listWrapper {
    width: calc(100vw - 310px);
  }
}

@media (min-width: 769px) {
  .listDesktopWrapper {
    height: calc(100vh);
    overflow: scroll;
  }
}

.listWrapper::-webkit-scrollbar {
  display: none;
}

.input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 18px;
  margin: 2px 0px;
  border: 1px solid lightgrey;
  outline: none;
  -webkit-appearance: none;
  border-radius: 4px;
  background-color: rgb(250, 250, 250);
}

.sectionText {
  color: gray;
  font-size: 14px;
  width: 100%;
  margin: auto;
  text-align: left;
  margin: 16px 0px 8px 0px;
}

.text {
  color: black;
  font-size: 16px;
  width: 100%;
  margin: auto;
  text-align: left;
  margin: 16px 0px 8px 0px;
}

.longInput {
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 4px;
  height: 32px;
  background-color: white;
  box-sizing: border-box;
  font-size: 18px;
  -webkit-appearance: none;
  outline: none;
  padding-left: 8px;
}

.editWrapper {
  display: flex;
  align-items: center;
}

.resetPassword {
  text-decoration: underline;
    width: 100%;
    text-align: right;
    margin: 16px 0px 8px 0px;
    color: gray;
}

.staffAccountExplanation {
  text-align: left;
  font-size: 12px;
}

.divider {
  margin: 20px 0px;
  border-bottom: 1px solid lightgray;
}