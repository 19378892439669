.chartContainer {
  height: 100%;
  width: 100%;
  /* margin-top: 48px; */
}

.wrapper {
  margin: auto;
}

@media (min-width: 769px) {
  .wrapper {
    width: calc(100vw - 310px);
  }
}

@media (min-width: 769px) {
  .wrapper {
    height: calc(100vh);
    overflow: scroll;
  }
}

.calender {
  height: 50px;
  width: 50px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #353535;
}

.total {
  width: 90%;
  bottom: 20px;
  position: fixed;
}

@media (min-width: 769px) {
  .total {
    width: 100%;
    bottom: 20px;
    position: absolute;
  }
}

.breakdowns {
  height: 100px;
  width: 100%;
}

.select {
  z-index: 100;
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  padding: 25px;
  font-size: 18px;
  border: none;
  /* color: #8884d8;
  background-color: #8884d8; */
  background-color: #004990;
  color: #004990;
  top: 0;
  outline: none;
  -webkit-appearance: none;
}

.graphsWrapper {
  width: 100%;
  margin: auto;
  height: 250px;
  margin-top: 50px;
  position: relative;
}

.graphWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.graphWrapper2 {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.calenderIcon {
  position: absolute;
  z-index: 100;
  pointer-events: none;
  color: white;
  top: 10px;
  left: 10px;
}


.intervals {
  display: flex;
  justify-content: space-around;
  height: 40px;
  align-items: center;
  color: lightgray;
  text-transform: uppercase;
  border-bottom: 1px solid lightgray;
}

.interval {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin: 0 16px;
  cursor: pointer;
}

.activeInterval {
  color: black;
  border-bottom: 2px solid #004990;
}

.addSale {
  position: absolute;
  right: 16px;
  top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.notificationsLink {
  position: absolute;
  right: 56px;
  top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

