
.navWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 32px);
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #004990;
    color: white;
    padding: 0 16px;
}

@media (min-width: 769px) {
    .navWrapper {
        width: calc(100vw - 302px);
        left: 270px;
    }
  }

.navWrapper > div:active {
    color: gray;

}

.navWrapper > div {
    cursor: pointer; 
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inactive {
    color: gray;
}