.app {
  display: flex;
  /* position: relative; */
  /* min-height: 100%; */
  height: 100%;
}

.main {
  width: 100%;
  /* min-height: 100vh; */
}

.main::-webkit-scrollbar {
  display: none;
}

.sidebarToggle {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 100;
}

@media (min-width: 769px) {
  .sidebarToggle {
    display: none;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: fixed;
  /* background-color: #8884d8; */
  background-color: #004990;

  color: white;
  width: 100vw;
  height: 50px;
  z-index: 90;
  top: 0;
}


@media (min-width: 769px) {
  .title {
    width: calc(100vw - 270px);
  }
}

.toast {
  background-color: red;
}
