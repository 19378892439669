.confirm {
  width: 100%;
  height: 50px;
  font-size: 18px;
  border: none;
  position: relative;
  margin: auto;
  background-color: #004990;
  color: white;
  margin-top: 16px;
  outline: unset;
  border-radius: 5px;
  cursor: pointer;
}

.spinner {
  width: 100%;
  text-align: center;
}

.spinner > div {
  width: 15px;
  height: 15px;
  background-color: white;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;

  margin: 0 4px;
  margin-top: 4px;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
