.listItemWrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  padding-right: 24px;
  box-shadow: 0 2px 10px rgba(15, 15, 28, 0.203);
  margin: 12px;
  position: relative;
  cursor: pointer;
}

.productDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 14px;
  width: 80%;
}

.productDescriptionActive {
  text-align: left;
  font-size: 14px;
  width: 80%;
}

.productDescriptionText {
  color: grey;
}

.productPrice {
  text-align: right;
  font-size: 16px;
}

.detailsWrapper {
  width: 90%;
}

.addSale {
  position: absolute;
  right: 16px;
  top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.salesLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  text-decoration: underline;
  text-align: right;
  margin-top: 8px;
  color: grey;
}

.actions > span {
  margin-left: 16px;
}

.listWrapper {
  margin-top: 58px;
}

@media (min-width: 769px) {
  .listWrapper {
    width: calc(100vw - 270px);
  }
}

@media (min-width: 769px) {
  .listDesktopWrapper {
    height: calc(100vh);
    overflow: scroll;
  }
}

.listWrapper::-webkit-scrollbar {
  display: none;
}

.calenderIcon {
  position: absolute;
  z-index: 100;
  height: 28px;
  width: 28px;
  right: 60px;
  top: 10px;
  color: white;
  cursor: pointer;
}

.backNavigation {
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 100;
}

.dateHeader {
  text-align: left;
  margin: 12px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}


.sectionHeader {
  color: gray;
  font-size: 14px;
  width: 100%;
  margin: auto;
  text-align: left;
  margin-top: 4px;
  width: 100px;
  display: inline-block;
}

.sectionText {
  color: black;
  font-size: 14px;
  margin-top: 4px;
  width: 100%;
  margin: auto;
  text-align: left;
}

.dateTime {
  margin-bottom: 8px;
}

.userAndPrice {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.klarnaIcon {
  background-image: url("../icons/klarna.webp");
  width: 24px;
  height: 13px;
  background-position: center;
  background-size: cover;
}