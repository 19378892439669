.wrapper {
  width: calc(100% - 40px);
  margin: 20px;
  margin-top: 70px;
}

.backNavigation {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 100;
}

.longInput {
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 4px;
  height: 32px;
  background-color: white;
  box-sizing: border-box;
  font-size: 18px;
  -webkit-appearance: none;
  outline: none;
  padding-left: 8px;
}

.inputCorrect {
  border: 1px solid green;
}

.inputIncorrect {
  border: 1px solid red;
  background-color: #fecec6;
}

.errorMessage {
  text-align: left;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 5px;
  position: absolute;
  right: 32px;
}

.priceWrapper {
  display: flex;
  align-items: center;
}

.sectionText {
  color: gray;
  font-size: 14px;
  width: 100%;
  margin: auto;
  text-align: left;
  margin: 16px 0px 8px 0px;
}

.allSettled {
  width: 50%;
  height: 40px;
  font-size: 18px;
  border: none;
  position: relative;
  margin: auto;
  color: #004990;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tillFloatWrapper {
  display: flex;
  align-items: center;
}

.tillFloatOuter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.addSale {
  position: absolute;
  right: 16px;
  top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
