.wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 20%;
  z-index: 1000;

  background-color: rgb(250, 250, 250);
  padding: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;

  border-radius: 4px;

  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}

.input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 18px;
  margin: 2px 0px;
  border: 1px solid lightgrey;
  outline: none;
  -webkit-appearance: none;
  border-radius: 4px;
  background-color: rgb(250, 250, 250);
}

.signIn {
  display: block;
  font-size: 18px;
  width: 100%;
  height: 40px;
  padding: 8px;
  margin: 8px 0px;
  margin-bottom: 0px;
  background-color: #004990;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.background {
  height: 100vh;
  width: 100vw;
}

.mobileImage {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.desktopImage {
  display: none;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

@media (min-width: 600px) {
  .desktopImage {
    display: block;
  }
  .mobileImage {
    display: none;
  }
  .wrapper {
    width: 50%;
    max-width: 600px;
    top: unset;
  }
  .background {
    display: flex;
    align-items: center;
  }
}


.signUp {
  margin-top: 16px;
  display: block;
  text-decoration: underline;
  color: grey;
  cursor: pointer;
}

.register {
  font-size: 24px;
  margin-bottom: 16px;
}

.sectionText {
  color: gray;
  font-size: 14px;
  width: 100%;
  margin: auto;
  text-align: left;
  margin: 16px 0px 8px 0px;
}


.termsAndConditionsWrapper {
  margin: 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.termsAndConditionsCheckbox {
  width: 20px;
  height: 20px;
}

.termsAndConditionsLink {
  text-decoration: underline;
  cursor: pointer;
}


.appName {
  font-family: "Pacifico", sans-serif;
  font-size: 32px;
  margin-bottom: 16px;
  height: 56px;
}

.tagline {
  font-size: 18px;
  color: gray;
  margin-bottom: 16px;
}

.error {
  background-color: #FFCCCB;
  border: 1px solid red;
  padding: 8px;
  margin-top: 8px;
  text-align: left;
}

.policyWrapper {
  height: 90%;
  width: 90%;
  max-width: unset;
  top: 5%;
  display: block;
  text-align: left;
  overflow: scroll;
}

.acceptTextWrapper {
  text-align: left;
  line-height: 22px;
}


.tcAccepted {
  border-color: green;
}

.inputError {
  border: 1px solid red;
}

.tcInputError {
  border: 1px solid red;
}
