.graphsWrapper {
  width: 100%;
  margin: auto;
  height: 250px;
  margin-top: 50px;
  position: relative;
}

.graphWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.graphWrapper2 {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.responsiveContainer {
  height: calc(100% - 50px) !important;
  margin-top: 50px;
}