.wrapper {
    width: calc(100% - 40px);
    margin: 20px;
    margin-top: 70px;
  }
  
  .listItemWrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-right: 24px;
    box-shadow: 0 2px 10px rgba(15, 15, 28, 0.203);
    margin: 12px;
    position: relative;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }
  
  .listWrapper {
    margin-top: 68px;
  }
  
  @media (min-width: 769px) {
    .listWrapper {
      width: calc(100vw - 270px);
    }
  }
  
  @media (min-width: 769px) {
    .listDesktopWrapper {
      height: calc(100vh);
      overflow: scroll;
    }
  }
  
  .listWrapper::-webkit-scrollbar {
    display: none;
  }
  
  .notificationWrapper {
    width: 90%;
    font-size: 14px;
    text-align: left;
    color: #505050;
  }
  
  .divider {
    width: calc(100% - 32px);
    height: 1px;
    margin: 32px 16px;
    border-bottom: 1px lightgray solid;
  }
  
  .notificationTime {
    margin-bottom: 8px;
  }

  .eod {
    width: 50%;
    height: 40px;
    font-size: 16px;
    border: none;
    position: relative;
    margin: auto;
    margin-bottom: 32px;
    color: grey;
    margin-top: 64px;
    text-decoration: underline;
    cursor: pointer;
  }

  .backNavigation {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    position: absolute;
    top: 10px;
    left: 16px;
    z-index: 100;
  }