.wrapper {
  margin-top: 70px;
}

.listItemWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-right: 24px;
  box-shadow: 0 2px 10px rgba(15, 15, 28, 0.203);
  margin: 12px;
  position: relative;
  align-items: center;
  font-size: 18px;
}

.listWrapper {
  margin-top: 58px;
}

@media (min-width: 769px) {
  .listWrapper {
    width: calc(100vw - 270px);
  }
}

@media (min-width: 769px) {
  .listDesktopWrapper {
    height: calc(100vh);
    overflow: scroll;
  }
}

.listWrapper::-webkit-scrollbar {
  display: none;
}


.button {
  width: calc(100% - 24px);
  margin: 12px;
}

.uploadComplete {
  background-color: lightgray;
}

.multiselectWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.optionWrapper {
  width: 50%;
  height: 60px;
  border: 1px lightgray solid;
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
}

.isSelected {
  border: 1px black solid;
}