.wrapper {
  width: calc(100% - 40px);
  margin: 20px;
  margin-top: 70px;
}

.listItemWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-right: 24px;
  box-shadow: 0 2px 10px rgba(15, 15, 28, 0.203);
  margin: 12px;
  position: relative;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.listWrapper {
  margin-top: 68px;
}

@media (min-width: 769px) {
  .listWrapper {
    width: calc(100vw - 270px);
  }
}

@media (min-width: 769px) {
  .listDesktopWrapper {
    height: calc(100vh);
    overflow: scroll;
  }
}

.listWrapper::-webkit-scrollbar {
  display: none;
}

.eod {
  width: calc(100% - 24px) !important;
  height: 40px;
  font-size: 16px;
  border: none;
  position: relative;
  margin: auto;
  margin-bottom: 32px;
  color: grey;
  margin-top: 64px;
  cursor: pointer;
}

.longInput {
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 4px;
  height: 32px;
  background-color: white;
  box-sizing: border-box;
  font-size: 18px;
  -webkit-appearance: none;
  outline: none;
  padding-left: 8px;
}

.greeting {
  font-size: 32px;
  margin: 16px;
}

.message {
  margin: 16px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
}

.addSale {
  position: absolute;
  right: 16px;
  top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.floatWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  width: 40%;
  margin: auto;
  margin-top: 32px;
  height: 57px;
}

.image {
  background-color: white !important;
}

.initial {
  font-size: 16px;
  width: 20px;
  height: 20px;
  line-height: 47px;
  color: #505050;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 14px;
  width: 80%;
}

.detailsWrapper {
  width: 90%;
  font-size: 18px;
  text-align: left;
  color: #505050;
}

.notificationWrapper {
  width: 90%;
  font-size: 14px;
  text-align: left;
  color: #505050;
}

.divider {
  width: calc(100% - 32px);
  height: 1px;
  margin: 32px 16px;
  border-bottom: 1px lightgray solid;
}

.notificationTime {
  margin-bottom: 8px;
}